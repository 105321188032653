import { ITraceData } from "../../types/charts/chartType/chartType";

import { WELL_ECONOMICS_TARGET_UOM } from "../panels/wellPanel/wellEconomics";

export const REVENUE_BAR_DATA: ITraceData = {
  id: "total-revenue",
  unit: "$",
  mode: "none",
  hoverlabel: { namelength: -1 },
  hoverinfo: "x+y",
  hovertemplate: "(%{x}, %{y}) <br>Total Revenue (Unhedged), $<extra></extra>",
  text: "",

  type: "bar",
  x: [],
  y: [],
  marker: { color: "#00838F" },
  name: "Total Revenue (Unhedged)",
};

export const OPERATING_COST_BAR_DATA: ITraceData = {
  id: "total-operating-cost",
  unit: "$",
  mode: "none",
  hoverlabel: { namelength: -1 },
  hoverinfo: "x+y",
  hovertemplate: "(%{x}, %{y}) <br>Total Operating Cost, $<extra></extra>",
  text: "",

  type: "bar",
  x: [],
  y: [],
  marker: { color: "#AD1457" },
  name: "Total Operating Cost",
};

export const ASSET_LEVEL_SCATTER_DATA: ITraceData = {
  id: "asset-level-fcf",
  unit: "$",
  mode: "lines",
  hoverlabel: { namelength: -1 },
  hoverinfo: "x+y",
  hovertemplate: "(%{x}, %{y}) <br>Asset-Level FCF, $<extra></extra>",
  text: "",

  type: "scatter",
  x: [],
  y: [],
  marker: { color: "#1DE9B6" },
  name: "Asset-Level FCF",
};

export const TARGET_UOM_ECONOMICS = {
  boe: {
    key: WELL_ECONOMICS_TARGET_UOM.BOE,
    label: WELL_ECONOMICS_TARGET_UOM.BOE.toLocaleLowerCase(),
  },
  mcfe: {
    key: WELL_ECONOMICS_TARGET_UOM.MCFE,
    label: WELL_ECONOMICS_TARGET_UOM.MCFE.toLocaleLowerCase(),
  },
} as const;

export const ECONOMICS_DISPLAY_BY = {
  allWells: {
    key: "allWells",
    label: "All Wells",
  },
  attribute: {
    key: "attribute",
    label: "By Attribute",
  },
} as const;
